.call {
    padding: 180px;
    width: 100%;
    height: 600px;
    background: linear-gradient(131.76deg, rgba(146, 3, 255, 0.774) 1.09%, #070789b0 72.22%);
    color: white;
    font-family: Roboto;
}

.call > h1 {
    font-size: 80px;
    margin: 20px auto;
}

.call > h6 {
    font-weight: 800;
    color: #FF647C;
    margin-top: 30px;
}

.call > h1 > a {
    color: white;
}

.call > * {
    max-width: 60%;
    margin: 0 auto;
}

.top {
    position: absolute;
    top: 0;
    right: 50px;
}

.bottom-left {
    position: absolute;
    left: 0;
    max-height: 100%;
}

.padding-top {
    padding-top: 20px;
}

.why, .want {
    padding-top: 70px;
    padding-bottom: 70px;
}
.want > h3, .why > h3 {
    position: static;
    width: 329px;
    height: 38px;
    left: 0px;
    top: 0px;

    font-family: Roboto;
    font-weight: 500;
    font-size: 32px;
    line-height: 37px;
    text-align: center;

    /* Gray 2 */

    color: #4F4F4F;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    align-self: center;
    margin: 30px auto;
}

.want > p, .why > p {
    width: 50%;
    margin: 50px auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #828282;
    text-align: center;
}

.what {
    width: 100%;
    height: 500px;
    background: linear-gradient(321.24deg, rgb(71, 0, 151) -3.67%, #3100b8 76.97%);
}

.want > p {
    width: 30%;
}

.want > .btn-primary {
    background-color: #ffa707;
    border-color: #ffa707;
}

.do {
    padding: 20px;
    height: 72px;
    width: 72px;
    border: 2px solid #00C48C;
    border-radius: 36px;
    display: inline-block;
}

.dont {
    padding: 20px;
    height: 72px;
    width: 72px;
    border: 2px solid #FF647C;
    border-radius: 36px;
    display: inline-block;
}

.point > p {
    color: white;
    margin: auto 0px;
    padding-left: 20px;
    max-width: 400px;
    text-align: left;
    display: inline-block
}

.point { 
    margin-top: 20px;
}

.what {
    padding: 80px;
}

h3 {
    color: white;
    font-family: Roboto;
    margin-bottom: 20px;
}

.background {
    background-image: url('https://res.cloudinary.com/dxirmkc1j/image/upload/v1586156406/background_xk1vrx.png');
    position: absolute;
    height: 600px;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: -1;
}

.exit-screen {
    position: relative;
}

.exit-screen .background, .exit-screen .call {
    height: 360px;
}

.exit-screen .background {
    background-size: cover;
    background-position: center;
}

.exit-screen .call > * {
    max-width: 20%;
}

.exit-screen .logo {
    color: #fff;
    background: #be52f2;
    border: 1px solid #be52f2;
    border-radius: 3px;
    padding: 5px;
    font-weight: 700;
    position: absolute;
    top: 20px;
    left: 20px;
}

.message-container {
    background-color: #9900ff;
    position: absolute;
    color: #ffffff;
    left: 5%;
    top: 15%;
    width: calc(100% - 10%);
    height: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 5px 0px #0e0b0b;
}

.message-container h2 {
    font-size: 80px;
    font-weight: 600;
}

.message-container p {
    font-size: 20px;
    padding: 0 20px;
}

.exit-screen .resources {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
}

.exit-screen .resources h3 {
    color: #595858;
    margin: 10px 0;
    width: 100%;
    text-align: center;
}

.exit-screen .resources a {
    color: #0b98a7;    
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-decoration: underline;    
}

.exit-screen .footer {
    background-color: #353a40;
    text-align: center;
    width: 100%;
    color: #ffffff;
    font-size: 12px;
    padding: 15px 15px 30px;
}

@media only screen and (max-width: 1490px) {
    .call {
        padding-top: 100px;
        padding-left: 30px;
        padding-right: 30px;
    }
    .call > h1 {
        font-size: 70px;
    }
}

@media only screen and (max-width: 1200px) {
    .call > h1 {
        font-size: 50px;
    }
    .do, .dont {
        padding: 10px;
        height: 50px;
        width: 50px;
        border-radius: 25px;
        margin-top: 10px;
    }
    .point > p {
        padding-left: 10px;
        max-width: 300px;
        font-size: 13px;
    }
    
    .point { 
        margin-top: 20px;
        height: 75px;
    }
    .message-container h2 {
        font-size: 54px;        
    }

    .message-container p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 900px) {
    .do, .dont {
        padding: 10px;
        height: 40px;
        width: 40px;
        border-radius: 20px;
    }
    .point > p {
        max-width: 220px;
    }    
}

@media only screen and (max-width: 700px) {
    .call {
        padding-top: 100px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .call > h1 {
        font-size: 30px;
    }
    .do, .dont {
        padding: 10px;
        height: 40px;
        width: 40px;
        border-radius: 20px;
    }
    .what {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 85px;
    }
    .point > p {
        max-width: 150px;
        font-size: 12px;
        text-align: center;
    }

    .want > p, .why > p {
        width: 80%;
    }
    .exit-screen .background, .exit-screen .call {
        height: 500px;
    }
    .message-container {
        top: 10%;
        height: 45%;
    }    
    .message-container h2 {
        font-size: 36px;        
    }

    .message-container p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 500px) {
    .call {
        padding-top: 100px;
        padding-left: 0px;
        padding-right: 0px;
    }
    .call > h1 {
        font-size: 25px;
    }
    .do, .dont {
        padding: 10px;
        height: 40px;
        width: 40px;
        border-radius: 20px;
    }
    .what {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 85px;
    }
    .point > p {
        max-width: 130px;
        font-size: 12px;
        text-align: center;
    }
}